.hint-box{
    padding: 24px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background: #FBFBFB;
    align-items: center;
}

.hint-box__text{
    text-align: center;
    color: #1A1A1A;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.hint-box__empty{
    width: 100%;
    margin-top: -10px;
}