.custom-categories {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px 0;
    justify-content: center;
    margin-top: -56px;
}

.custom-categories__item {
    padding: 0 7px;
    display: flex;
    flex-direction: column;
    max-width: 214px;
}

.custom-categories__img {
    aspect-ratio: 1 / 1;
    object-fit: cover;
}

@media (max-width: 1000px) {
    .custom-categories{
        margin-top: -10px;
    }
}


@media (max-width: 580px) {
    .custom-categories__item{
        max-width: 44vw;
        padding: 0 2px;
    }
    .custom-categories{
        gap: 12px 0;
    }
}

@media (max-width: 340px) {
    .custom-categories__item{
        max-width: 42vw;
    }

}
