.header {
    width: 100%;

    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    position: sticky;
    transition: all 0.3s ease-in-out;
    z-index: 11;
    background-color: var(--website_background-background);
}


.header_hide {
    top: -200px;
}

.header_show {
    top: 0;
}

.header__input-icon {
    position: relative;
    z-index: 5;
    margin-left: 12px;
}

.header__input-icon-fill{
    fill: var(--neutral-active);
}

.header__content {
    max-width: 1280px;
    width: 100%;
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    column-gap: 32px;
    align-items: center;
    position: relative;
    z-index: 11;
    background-color: var(--website_background-background);
    padding: 16px 32px 16px;
    box-sizing: border-box;
}

.header__logo {
    /* width: 100%; */
}

.header__logo-img {
    /* width: 100%; */
    max-width: 154px;
    max-height: 40px;
    object-fit: scale-down;
    object-position: left;
}

.header__catalog-with-links {
    display: grid;
    grid-template-columns: max-content 24px minmax(0, 1fr);
    grid-template-rows: max-content;
    align-items: center;
    column-gap: 28px;
    position: relative;
}

.header__catalog {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 32px;
}


.header__catalog-btn {
    background-color: var(--secondary-active);
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    gap: 6px;
    padding: 6px 12px 6px 8px;
    border-radius: 6px;
}

.header__catalog-btn-text {
    /* color: var(--neutral-active); */
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.header__categories {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 32px;
}

.header__category {
    font-size: var(--h4-size);
    font-weight: var(--h4-weight);
    color: var(--neutral-active);
}


.header__cart-box {
    position: relative;

}

.header__cart {
    background-color: var(--primary-active);
    color: var(--website_background-background);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    padding: 6px 12px 6px 8px;
    border-radius: 6px;
}

.header__cart-text {
    /* color: var(--website_background-background); */
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.header__cart-icon {
    width: 24px;
    height: 24px;
}

.header__cart-icon-fill {
    fill: var(--website_background-background);
}

.header__cart-count {
    font-size: var(--caption-size);
    font-weight: var(--caption-weight);

    position: absolute;
    right: -6px;
    top: -6px;
    background-color: var(--primary-background);
    color: #1A1A1A;
    border-radius: calc(var(--initial_border_radius-value) * (1.50 * 1));
    min-width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
    box-sizing: border-box;
}

.header__catalog-btn-icon {
    width: 24px;
    height: 24px;
}

.header__catalog-btn-icon-fill {
    fill: #FFF;
}

.header__catalog-btn-icon_close-catalog {
    position: absolute;
    z-index: 3;
    opacity: 0;
    background-color: var(--secondary-active);
    transition: all 0.2s ease-in-out;
}

.header__catalog-btn-icon_close-catalog-open {
    opacity: 1;
}

.header__menu {
    display: none;
}

.header__menu-icon {
    width: 34px;
    height: 34px;
}

.header__menu-icon-fill {
    fill: var(--neutral-active);
}

.header__cart-and-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    justify-content: flex-end;
}

.header__links {
    width: 100%;
    display: flex;
    overflow: hidden;
    white-space: nowrap;

    position: relative;
}

.header__link {
    flex: none;
    padding: 0 28px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    transition: all 0.2s linear;
}

.header__link_active {
    color: var(--primary-active);
}

.header__search-box {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.search_mobile {
    display: none;
}

.search_pc {
    display: flex;
}

.header__search-icon-mobile{
    display: none;
    padding: 12px 12px;
}

@media (max-width: 1000px) {
    .header__catalog-with-links {
        column-gap: 6px;
    }
}

@media (max-width: 780px) {
    .search_mobile {
        display: flex;
    }
    .header__search-icon-mobile{
        display: flex;
    }

    .search_pc {
        display: none;
    }

    .header__content {
        grid-template-columns: minmax(max-content, 154px) 1fr;
    }

    .header__menu {
        display: flex;
    }

    .header__input-icon {
        margin-top: 30px;
    }

    .header__catalog-with-links {
        display: none;
    }

    .header__catalog-btn {
        display: none;
    }

    .header__cart {
        width: 48px;
        height: 48px;
        box-sizing: border-box;
        padding: unset;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .header__cart-text {
        display: none;
    }

    .header__content {
        padding: 16px;
    }
}