.main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    box-sizing: border-box;
    padding: 0 0 0;
}

.main__content {
    width: 100%;
    max-width: 1280px;
    padding: 0 32px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    gap: 80px;
}


@media (max-width: 1000px) {

    .main{
        padding: 0 0 0;
    }
    .main__content{
        padding: 0 16px;
        gap: 40px;
    }

}
