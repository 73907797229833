.custom-advantages {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: max-content;
    column-gap: 0px;
    margin-bottom: -68px;
    margin-top: -70px;
}

.custom-advantages__card {
    padding: 16px 24px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 40px 1fr;
    grid-template-rows: max-content;
    column-gap: 12px;
    align-items: center;
}

.custom-advantages__card-icon {
    width: 40px;
    height: 40px;
}

.custom-advantages__card-text {
    color: #1A1A1A;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

@media (max-width: 1120px) {

    /* .custom-advantages {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: max-content max-content;
    } */
    .custom-advantages__card-text {
        font-size: 12px;
    }
}

@media (max-width: 1000px) {
    .custom-advantages {
        margin-top: -30px;
        margin-bottom: -30px;
    }
}


@media (max-width: 800px) {
    .custom-advantages {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: max-content max-content;
    }

    .custom-advantages__card-text {
        font-size: 14px;
    }
}


@media (max-width: 520px) {
    .custom-advantages__card-text {
        font-size: 12px;
    }
}

@media (max-width: 410px) {
    .custom-advantages {
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: scroll;
        width: 100%;
        grid-template-columns: unset;
        grid-template-rows: unset;
    }

    .custom-advantages__card {
        width: 210px;
        min-width: 210px;
        padding: 14px 10px;
        grid-template-columns: 32px 1fr;
    }
    .custom-advantages__card-icon{
        width: 32px;
        height: 32px;
    }
}