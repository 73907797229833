.address {
    width: 100%;
    display: grid;
    grid-template-columns: 380px 1fr;
    grid-template-rows: max-content;
    column-gap: 20px;
    margin-top: calc(-80px + 32px);
}

.address__info {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
}

.address__container {
    padding: 24px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background: #FBFBFB;
}

.address__container-title-box {
    width: 100%;
    display: grid;
    grid-template-columns: 40px 1fr;
    grid-template-rows: max-content;
    column-gap: 16px;
    align-items: center;
}

.address__container-title-icon {
    width: 40px;
    height: 40px;
}

.address__container-title {
    color: #1A1A1A;

    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.address__timetable {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 32px 0 0;
}

.address__timetable-text {
    color: #1A1A1A;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}

.address__timetable-separator {
    width: 100%;
    height: 1px;
    margin: 20px 0;
    background: #EDEDED;
}

.address__timetable-hint {
    color: #707070;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin: 24px 0 0;
}

.address__map {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
}

@media (max-width: 1000px) {
    .address {
        margin-top: calc(-40px + 32px);
    }
}


@media (max-width: 720px) {
    .address {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 550px) {
    .address {
        grid-template-columns: unset;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .address__map{
        height: 300px;
    }

}