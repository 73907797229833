.static-docs {
    width: 100%;
    max-width: 1280px;
    padding: 0 32px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    min-height: 100vh;
}

.static-docs__text {
    white-space: pre-line;
}


.static-doc__title {
    color: var(--primary-active);
}

.static-doc__img {
    width: 100%;
}

@media (max-width: 600px) {
    .static-docs{
        padding: 0 12px;
    }
    .static-doc__img {
        width: 100%;
        height: 200px;
        object-fit: cover;
    }
   }
