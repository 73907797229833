.submit-act-popup {
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: visibility 0.3s, opacity 0.3s linear;
}

.submit-act-popup_active {
  visibility: visible;
}


.submit-act-popup__container {
  width: 500px;

  z-index: 1001;

  padding: 20px;
  position: relative;
  /* padding-bottom: 70px;
	transform: translateY(70px); */
  display: flex;
  flex-direction: column;
  opacity: 0;
  transition: opacity 0.2s linear;
  box-sizing: border-box;
  border-radius: 12px;
  padding-bottom: 10px;
  background-color: #FFFFFF;
}

.submit-act-popup__container_active {
  opacity: 1;
}

.submit-act-popup__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(26, 26, 26, 0.65);
  opacity: 0;
  z-index: 999;
  transition: opacity 0.4s linear;
}

.submit-act-popup__background_active {
  opacity: 1;
  transition: opacity 0.4s linear;
}

.submit-act-popup__close {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  /* width: 24px;
  height: 24px; */
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
  margin: 16px 16px 0 auto;
}

.submit-act-popup__close-icon {
  width: 24px;
  height: 24px;
}

.submit-act-popup__close-icon-stroke {
  stroke: var(--text-color);
}

.submit-act-popup__icon{
  margin: 0 auto;
  margin-bottom: 20px;
}
.submit-act-popup__title {
  color: #1A1A1A;

  text-align: center;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  /* 125.926% */
  letter-spacing: 2.16px;
  /* text-transform: uppercase; */
}

.submit-act-popup__title_second{
  font-weight: 600;
  font-size: 24px;
}

.submit-act-popup__text {

  font-size: 14px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  line-height: 115%;
  /* 14.3px */
  letter-spacing: -0.78px;
  color: var(--neutral-active);
  margin: 24px 0 0;
}

.submit-act-popup__btns {
  display: grid;
  grid-template-columns: repeat(2, minMax(0, 1fr));
  grid-template-rows: max-content;
  column-gap: 12px;
  flex-direction: column;
  margin: 32px 16px 6px;
}


.submit-act-popup__btns_second-step{
  grid-template-columns: repeat(1, minMax(0, 1fr));
}


.submit-act-popup__btn {
  width: 100%;
  padding: 14px 8px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 15px */
  letter-spacing: -0.9px;
  border-radius: 8px;
  background-color: #83C452;
  color: #FFFFFF;

}

.submit-act-popup__btn_agree {
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  /* 15px */
  letter-spacing: -0.9px;

  color: #FFFFFF;
  text-align: center;
}

.submit-act-popup__btn-text_agree {
  color: #FFFFFF;
}

.submit-act-popup__btn_disagree {
  background-color: #F8F8FA;
  color: rgba(26, 26, 26, 0.65);
}

.submit-act-popup__btn-text_disagree {
  color: rgba(26, 26, 26, 0.65);
}

.submit-act-popup__phone{
  font-size: 16px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  line-height: 115%;
  /* 14.3px */
  letter-spacing: -0.78px;
  color: var(--neutral-active);
  margin: 24px 0 0;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: center;
}

@media (max-width: 448px) {
  .submit-act-popup__container {
    width: 98%;

  }

  .submit-act-popup__text {
    font-size: 16px;
  }

  .submit-act-popup__title {
    font-size: 24px;
  }

  .submit-act-popup__text {
    font-size: 14px;
    line-height: 20px;
  }
}